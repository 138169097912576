import "./selectStylePage.css"
import sampleImage from "../../assets/Jeff.jpeg"
import aiIcon from "../../assets/ai-icon.svg"
import Button from "../../components/button/button";
import PictureContainer from "../../components/pictureContainer/pictureContainer";
import Title from "../../components/title/title";
import StyleContainer from "../../components/styleContainer/styleContainer";
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router'
import { useMemo, useEffect, useState } from 'react'
import { api } from '../../api'

const text = 'Selecciona tu estilo'

function SelectStylePage() {
  const [uploading, setUploading] = useState(false)
  const [styles, setStyles] = useState([])
  const [style, setStyle] = useState({})
  const location = useLocation()
  const navigate = useNavigate()

  const image = useMemo(
    () => (location?.state?.imageSrc ? location?.state?.imageSrc : sampleImage),
    [location]
  )

  const stylesCard = styles.map((s) => (
    <button
      className={`style-select-button ${
        style.id === s.id ? 'style-selected' : ''
      }`}
      onClick={() => setStyle(s)}
      key={s.id}
    >
      <StyleContainer name={s.name} image={s.image} />
    </button>
  ))

  useEffect(() => {
    const fetchStyles = async () => {
      const response = await api.styles.list()
      setStyle(response[0])
      setStyles(response)
    }
    fetchStyles()
  }, [])

  const generateStyle = async () => {
    setUploading(true)
    const response = await api.generateStyle(
      image,
      style,
      location?.state?.imageKey
    )
    if (response?.key) {
      navigate('/generatedStyle', {
        state: { imageSrc: image, imageKey: response.key, style },
      })
      setUploading(false)
    }
  }

  return (
    <div className="style-page screen-h container">
      <Title text={text} />
      {uploading && (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {!uploading && (
        <>
          <div className="select-main-container">
            <div className="user-picture">
              <PictureContainer image={image} />
            </div>
            <div className="style-container-list">{stylesCard}</div>
          </div>
          <div className="button-container">
            <Button text="Generar" icon={aiIcon} handleClick={generateStyle} />
          </div>
        </>
      )}
    </div>
  )
}

export default SelectStylePage;