import './galleyPage.css'
import PictureContainer from '../../components/pictureContainer/pictureContainer'
import { useEffect, useState } from 'react'
import { api } from '../../api'

function GalleryPage() {
  const [loading, setLoading] = useState(true)
  const [imagesURL, setImagesURL] = useState([])

  useEffect(() => {
    const getPhotos = async () => {
      const response = await api.listPhotos('processed/')
      setImagesURL(response)
      setLoading(false)
    }

    setInterval(async () => {
      setLoading(true)
      setImagesURL([])
      const response = await api.listPhotos('processed/')
      setImagesURL(response)
      setLoading(false)
    }, 120000)

    getPhotos()
  }, [])

  return (
    <div className="screen-h container gallery">
      {loading && (
        <div className="loading-container">
          <div className="lds-ring m-t">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      {!loading && (
        <div className="gallery-container">
          {!loading &&
            imagesURL.length > 0 &&
            imagesURL.map((image) => (
              <PictureContainer key={image.id} image={image.url} />
            ))}
        </div>
      )}
    </div>
  )
}

// export ConfirmPhotoPage function
export default GalleryPage
