import "./confirmPhotoPage.css"
import sampleImage from "../../assets/Jeff.jpeg"
import repeatIcon from "../../assets/repeat-icon.svg"
import confirmIcon from "../../assets/confirm-icon.svg"
import Button from "../../components/button/button";
import PictureContainer from "../../components/pictureContainer/pictureContainer";
import Title from "../../components/title/title";
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useMemo } from 'react'

const text = 'Confirma tu foto'

function ConfirmPhotoPage() {
  const location = useLocation()
  const image = useMemo(
    () => (location?.state?.imageSrc ? location?.state?.imageSrc : sampleImage),
    [location]
  )

  const navigate = useNavigate()

  const retakePhoto = () => {
    navigate('/photo')
  }

  const confirmPhoto = () => {
    navigate('/selectStyle', { state: { imageSrc: image } })
  }

  return (
    <div className="confirm-photo screen-h">
      <div className="container">
        <Title text={text} />
        <PictureContainer image={image} />
        <div>
          <p className="auth">
            Autorizo el uso y manejo de mi imagen para su utilización en el
            procesamiento mediante Machine Learning de esta Demo.
          </p>
        </div>
        <div className="confirm-button-container">
          <Button text="Repetir" icon={repeatIcon} handleClick={retakePhoto} />
          <Button
            text="Confirmar"
            icon={confirmIcon}
            handleClick={confirmPhoto}
          />
        </div>
      </div>
    </div>
  )
}

export default ConfirmPhotoPage;