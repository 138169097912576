import React from 'react';
import { Link } from 'react-router-dom';
import './eventTitle.css';
import aws from '../../assets/aws.png';

const EventTitle = (fontSize="20px") => {
    const myStyle = {
        fontSize: fontSize
    }

    return (
        <Link to="/">
        <div className="event-container">
            <img className="event-icon" src={aws} alt="AWS Logo" />
            <div className='line-container'>
                <div className='line'></div>
            </div>
            <div className="event-title" style={myStyle}>
                <div className="event-title-city">Buenos Aires 2023</div>
                <div className="event-title-event">Generative AI Insights</div>
            </div>
        </div>
        </Link>
    );
};

export default EventTitle;