import "./pictureContainer.css"

function PictureContainer({ image }) {
    return (
      <div className="picture-container">
        <img src={image} alt="selfie" className="picture-box" />
      </div>
    )
}

export default PictureContainer;