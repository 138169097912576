import "./title.css"

function Title({text, style={fontWeight: 'bold'}}) {
    return (
            <div className="title-container" style={style}>
                <div className="title-text">{text}</div>
            </div>
    );
}

export default Title;