import { Routes, Route } from "react-router-dom";
import { AmplifySignIn } from '@aws-amplify/ui-react'
import { Authenticator } from '@aws-amplify/ui-react';
import HomePage from './pages/home/homePage'
import ConfirmPhotoPage from './pages/confirmPhoto/confirmPhotoPage'
import FinalStylePage from './pages/FinalStyle/finalStylePage'
import GeneratedStylePage from './pages/generatedStyle/generatedStylePage'
import QrPage from './pages/qrCode/qrPage'
import SelectStylePage from './pages/selectStyle/selectStylePage'
import GalleryPage from './pages/gallery/galleryPage'
import Layout from './components/layout/layout'
import TakePhoto from './pages/takePhoto/takePhoto'

import './App.css'
import '@aws-amplify/ui-react/styles.css'

function App() {
  return (
    <Authenticator hideSignUp={true}>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/" element={<Layout />}>
          <Route path="/photo" element={<TakePhoto />} />
          <Route path="/confirmPhoto" element={<ConfirmPhotoPage />} />
          <Route path="/selectStyle" element={<SelectStylePage />} />
          <Route path="/generatedStyle" element={<GeneratedStylePage />} />
          <Route path="/confirmation" element={<FinalStylePage />} />
          <Route path="/qr" element={<QrPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="*" element={<ConfirmPhotoPage />} />
        </Route>
      </Routes>
    </Authenticator>
  )
}

export default App
