import React from 'react';
import { Link } from 'react-router-dom';
import './navbar.css'
import EventTitle from '../eventTitle/eventTitle';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <EventTitle />
        <ul className="navbar-menu">
          <li className="navbar-menu-item">
            <Link to="/photo" className="navbar-menu-link">
              Photo
            </Link>
          </li>
          <li className="navbar-menu-item">
            <Link to="/gallery" className="navbar-menu-link">
              Gallery
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
};

export default Navbar;