import './qrPage.css'
import repeatIcon from '../../assets/repeat-icon.svg'
import Button from '../../components/button/button'
import BadgeContainer from '../../components/badgeContainer/badgeContainer'
import Title from '../../components/title/title'
import PictureContainer from '../../components/pictureContainer/pictureContainer'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { api } from '../../api'

const text = 'Escanea el QR para descargar tu imagen'

function QrPage() {
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()
  const { imageGenerated, imageKey } = location.state
  const [qrImage, setQrImage] = useState(null)

  const retakePhoto = () => {
    navigate('/photo')
  }

  useEffect(() => {
    const getQRImage = async () => {
      const response = await API.post('qr', '/qr', {
        body: {
          key: imageKey,
          presigned_url: imageGenerated,
        },
        headers: {},
      })
      const key = response
      console.log(response)
      const url = await api.getImage(key)
      setQrImage(url)
      setLoading(false)
    }

    getQRImage()
  }, [imageGenerated, imageKey])

  return (
    <div className="style-page screen-h container">
      <Title text={text} />
      {loading && (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {!loading && (
        <div className="qr-container">
          <PictureContainer image={imageGenerated} />
          {qrImage && <PictureContainer image={qrImage} />}
          <div className="qr-button-container">
            <Button
              text="Repetir"
              icon={repeatIcon}
              handleClick={retakePhoto}
            />
          </div>
        </div>
      )}
    </div>
  )
}

// export ConfirmPhotoPage function
export default QrPage
