import PictureContainer from "../pictureContainer/pictureContainer";
import "./badgeContainer.css"
import EventTitle from '../eventTitle/eventTitle'

function BadgeContainer({ image, fontSize, style={maxWidth:"25rem"} }) {
    return (
        <div className="badge-container" style={style}>
            <PictureContainer image={image} />
            <EventTitle fontSize={fontSize} />
        </div>
    );
}

export default BadgeContainer;