import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import Camera from '../../components/camera/camera'
import Button from '../../components/button/button'
import CameraIcon from '../../assets/camera-icon.svg'
import Title from '../../components/title/title'

import './takePhoto.css'

const TakePhoto = () => {
  const webcamRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const takePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot()
    navigate('/confirmPhoto', { state: { imageSrc: imageSrc } })
  }

  const handleUserMedia = () => {
    setLoading(false)
  }

  return (
    <div className="take-photo screen-h container">
      <Title text={`¡Tómate la foto!`} />
      {loading && (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      <Camera
        loading={loading}
        webcamRef={webcamRef}
        handleUserMedia={handleUserMedia}
      />
      {!loading && (
        <div className="take-photo-button-container">
          <Button text="Tomar Foto" icon={CameraIcon} handleClick={takePhoto} />
        </div>
      )}
    </div>
  )
}

export default TakePhoto
