import spartanImage from '../assets/300.jpg'
import starWarsImage from '../assets/starwars.jpg'
import marvelImage from '../assets/marvel.jpg'
import romeImage from '../assets/rome.jpg'
import { Storage } from 'aws-amplify'

const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export const api = {
  generateStyle: async (image, style, imageKey = null) => {
    console.log('Uploading...')
    let key = ''
    if (imageKey) {
      key = imageKey
    } else {
      const id = new Date().toISOString()
      key = `selfies/${id}-selfie.png`
    }
    const blob = dataURLtoBlob(image)
    const response = await Storage.put(key, blob, {
      contentType: 'image/png',
      metadata: {
        query: style.name,
      },
    })
    return response
  },
  getImage: async (key) => {
    const result = await Storage.get(key)
    return result
  },
  async listPhotos(path) {
    const response = await Storage.list(path, { pageSize: 'ALL' })
    const results = response.results.reverse().slice(0, 25)
    const photos = Promise.all(
      results.map(async (photo) => {
        const url = await this.getImage(photo.key)
        return {
          id: photo.eTag,
          url,
        }
      })
    )
    return photos
  },
  styles: {
    list: async () => {
      // Implementar la logica para traer los estilos
      const styles = [
        {
          id: 1,
          name: 'Spartan',
          image: spartanImage,
          description: 'This is Sparta!',
        },
        {
          id: 2,
          name: 'Rome',
          image: romeImage,
          description: 'A Walk through ancient Rome',
        },
        {
          id: 3,
          name: 'StarWars',
          image: starWarsImage,
          description: 'In a Galaxy Far Far Away',
        },
        {
          id: 4,
          name: 'Marvel',
          image: marvelImage,
          description: 'Be a hero in a Marvel Movie',
        },
      ]

      return styles
    },
  },
}
