import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';

import { Amplify } from 'aws-amplify'
import { AWSIoTProvider } from '@aws-amplify/pubsub'
import awsconfig from './aws-exports'
Amplify.configure(awsconfig)

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: 'us-east-1',
    aws_pubsub_endpoint:
      'wss://a2i5cpu5p3yplo-ats.iot.us-east-1.amazonaws.com/mqtt',
  })
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
