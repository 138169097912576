import { useState, useEffect } from 'react'
import './generatedStylePage.css'
import repeatIcon from '../../assets/repeat-icon.svg'
import confirmIcon from '../../assets/confirm-icon.svg'
import cameraIcon from '../../assets/camera-icon.svg'
import Button from '../../components/button/button'
import PictureContainer from '../../components/pictureContainer/pictureContainer'
import Title from '../../components/title/title'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { api } from '../../api'
import { PubSub } from 'aws-amplify'

function GeneratedStylePage() {
  const [imageGenerated, setImageGenerated] = useState(null)
  const location = useLocation()
  const navigate = useNavigate()
  const { imageSrc, imageKey, style } = location.state

  const retakePhoto = () => {
    navigate('/photo')
  }

  const selectStyle = () => {
    navigate('/selectStyle', {
      state: { imageSrc, imageKey },
    })
  }

  const confirmStyle = () => {
    navigate('/qr', {
      state: { imageGenerated, imageKey },
    })
  }

  useEffect(() => {
    const sub = PubSub.subscribe('StyleGenerated').subscribe({
      next: async (data) => {
        let originalImageName = imageKey.split('/')[1].split('.')[0]
        let genereatedImageName = data.value.imageKey
          .split('/')[1]
          .split('.')[0]
        if (originalImageName === genereatedImageName) {
          const imageUrl = await api.getImage(data.value.imageKey)
          setImageGenerated(imageUrl)
        }
      },
      error: (error) => console.error(error),
      complete: () => console.log('Done'),
    })

    return () => {
      sub.unsubscribe()
    }
  }, [imageKey])

  return (
    <div className="style-page screen-h container">
      {!imageGenerated && (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {imageGenerated && (
        <>
          <Title
            text={`Foto con estilo "${style.name}"`}
            style={{ fontWeight: 'lighter' }}
          />
          <div className="generated-pictures-container">
            <PictureContainer image={imageSrc} />
            <PictureContainer image={imageGenerated} />
          </div>
          <div className="generate-button-container">
            <Button
              text="Nueva Foto"
              icon={cameraIcon}
              handleClick={retakePhoto}
            />
            <Button
              text="Generar de nuevo"
              icon={repeatIcon}
              handleClick={selectStyle}
            />
            <Button
              text="Confirmar"
              icon={confirmIcon}
              handleClick={confirmStyle}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default GeneratedStylePage