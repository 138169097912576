import React from "react";
import "./home.css";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'


export const HomePage = () => {


  return (
    <div className="screen-h main-container">
      <div className="home-title-container">
        <h1 className="title">GenAI Photobooth</h1>
        <div className="subtitle-container">
          <p className="subtitle">by</p>
          <img className="logo" alt="Group" src="aws.png" />
        </div>
      </div>
      <div className="botones">
        <Link to="/photo" className="link-button">
          <div className="boton boton-foto">
            <img
              className="solar-camera-bold"
              alt="Solar camera bold"
              src="solar_camera-bold.png"
            />
            <div className="text-button">Tomar foto</div>
          </div>
        </Link>

        <Link to="/gallery" className="link-button">
          <div className="boton">
            <img
              className="solar-camera-bold"
              alt="Solar camera bold"
              src="solar_camera-bold.png"
            />
            <div className="text-button">Galería</div>
          </div>
        </Link>
      </div>
      <div className="logo-evento">
        <img className="frame" alt="Frame" src="aws.png" />
        <img className="line" alt="Line" src="line.png" />
        <p className="bogot-cloud">
          <span className="span">
            Buenos Aires 2023
            <br />
            Gen AI
          </span>
          <span className="text-wrapper-4"> </span>
          <span className="text-wrapper-5">Insights</span>
        </p>
      </div>
    </div>
  )

};


export default HomePage
