import React from 'react'
import Webcam from 'react-webcam'
import './camera.css'

const videoConstraints = {
  width: 512,
  height: 512,
  aspectRatio: 1,
  facingMode: 'user',
}

const Camera = ({ loading, webcamRef, handleUserMedia }) => {
  return (
    <div className={`camera-container ${loading && 'hide'}`}>
      <Webcam
        className="camera"
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        videoConstraints={videoConstraints}
        onUserMedia={handleUserMedia}
        mirrored
      />
    </div>
  )
}

export default Camera
