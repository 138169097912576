import "./styleContainer.css"

function StyleContainer({image, name}) {
    return (
            <div className="style-picture-container">
                <img src={image} alt={name} className="style-picture" />
                <div className="style-text">
                    {name}
                </div>
            </div>
    );
}

export default StyleContainer;