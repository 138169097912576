import React from 'react';
import './button.css';


const Button = ({ text, icon, handleClick }) => {
  return (
    <button type="button" className="button" onClick={handleClick}>
      <img src={icon} alt="Icon" className="icon" />
      <span className="text">{text}</span>
    </button>
  )
}

export default Button;