import './finalStylePage.css'
import downloadIcon from '../../assets/download-icon.svg'
import Button from '../../components/button/button'
import Title from '../../components/title/title'
import BadgeContainer from '../../components/badgeContainer/badgeContainer'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'

const text = '¡Gracias por participar!'

function FinalStylePage() {
  const location = useLocation()
  const navigate = useNavigate()
  const { imageGenerated, imageKey } = location.state

  const handleDownload = async () => {
    navigate('/qr', { state: { imageGenerated, imageKey } })
  }

  return (
    <div className="style-page screen-h container">
      <Title text={text} />
      <BadgeContainer image={imageGenerated} />
      <div className="button-container">
        <Button
          text="Descargar"
          icon={downloadIcon}
          handleClick={handleDownload}
        />
      </div>
    </div>
  )
}

export default FinalStylePage
